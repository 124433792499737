<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
data(){
  return{
    webList:false
  }
}
}
</script>
<style lang="less">
#app {
  width: 100%;
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
