<template>
  <div class="home">
    <HeaderPart @showPopup='showPopup'></HeaderPart>
    <div class="content">
      <div class="center" v-loading="loading">

        <div class="about">
          <div class="title">
            ABOUT US
          </div>
          <p>
            {{ content }}
          </p>
        </div>
        <div class="news" id="news">
          <div class="title">
            NEWS
          </div>
          <div class="news_content" v-if="newsList[0].title != ''">
            <div class="last">
              <div class="news_img">
                <img src="@/assets/pic1.jpg" alt="">
              </div>
              <p class="news_title">
                {{ newsList[0].title }}
              </p>
              <div class="text">
                <p class="detail" v-html="newsList[0].content">

                </p>
                <div class="button" @click="toDetail(newsList[0])">
                  News detail
                </div>
              </div>
            </div>
            <div class="other_news">
              <div class="other_title" @click="toDetail(item)" v-for="(item, index) in newsList" :key="index">
                <span class="other_news_title">{{ item.title }}</span>
                <span style="text-decoration: underline; cursor: pointer;">detail</span>
              </div>
            </div>
          </div>
        </div>
        <div class="journal" id="journal">
          <div class="title">
            <span>JOURNAL CATEGORY</span>
            <div class="inputBox">
              <el-input style="padding: 0 30px 0 0;" v-model="keyWord" placeholder="Search categories"
                @keyup.enter.native="search()"></el-input>
              <i style="cursor: pointer; position: absolute; right: 40px;" class="el-icon-search" @click="search()"></i>
            </div>

          </div>
          <div class="classification">
            <div class="classs_item" v-for="(item, index) in classList" :key="index"
              @click="toClassList(item.id, item.name)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="Personnel" id="Personnel">
          <div class="title">
            PERSONNEL MIEN
          </div>
          <div class="person_content">
            <!-- <Swiper :imgList="imgList"></Swiper> -->
            <Swiper></Swiper>
          </div>
        </div>
      </div>
    </div>
    <bottom-part></bottom-part>
    <WebNavPart :webList="webList" @closePopup="webList = false"></WebNavPart>
  </div>
</template>

<script>
import HeaderPart from '@/components/HeaderPart';
import BottomPart from '@/components/BottomPart.vue';
import Swiper from "@/components/SwiperPart.vue";
import WebNavPart from '@/components/WebNavPart.vue';
import { get } from '../utils/http'
export default {
  components: {
    HeaderPart, Swiper, BottomPart, WebNavPart
  },
  data() {
    return {
      imgList: [],
      classList: [],
      keyWord: '',
      content: '',
      newsList: [{ title: '', content: '' }],
      loading: false,
      webList:false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      get('jeecg-portal/portal/profiles/getInfo').then((res) => {
        if (res.success) {
          this.content = res.result.content
        }
      })
      get('jeecg-portal/portal/news/list').then((res) => {
        if (res.success) {
          if (res.result.length > 8) {
            this.newsList = res.result.slice(0, 8)
          } else {
            this.newsList = res.result
          }

        }
      })
      get('jeecg-portal/portal/category/list').then((res) => {
        if (res.success) {
          this.classList = res.result
          if (this.classList.length % 3 == 2) {
            this.classList.push({})
          }
          this.loading = false

        }
      })

    },
    search() {
      this.loading = true
      get('jeecg-portal/portal/category/list', { name: this.keyWord }).then((res) => {
        if (res.success) {
          this.classList = res.result
          if (this.classList.length % 3 == 2) {
            this.classList.push({})
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    toClassList(id, name) {
      localStorage.setItem('classId', id)
      localStorage.setItem('className', name)
      if (id) {
        this.$router.push({
          path: '/class',
        })
      }

    },
    toDetail(value) {
      localStorage.setItem('value', JSON.stringify(value))
      this.$router.push({
        name: 'detail'
      })
    },
    showPopup(){
      this.webList=true
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  position: relative;

  .content {
    padding-top: 140px;

    .center {
      margin-top: 50px;

      .about {
        background-color: #00285a;
        color: white;
        padding: 30px;

        .title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        p {
          line-height: 30px;
          text-align: justify;
        }
      }

      .news {
        margin-top: 50px;
        padding: 30px;

        .title {
          font-weight: bold;
          font-size: 22px;
          color: #00285a;
          margin-bottom: 30px;
        }

        .news_content {
          width: 100%;
          display: flex;

          .last {
            width: 400px;
            margin-right: 30px;

            .news_img {
              width: 400px;
              overflow: hidden;

              img {
                width: 100%;
                vertical-align: top;
              }
            }

            .news_title {
              background: #8e8e8e;
              padding: 0 20px;
              line-height: 50px;
              color: #ffffff;
              font-size: 18px;
              white-space: nowrap;
              /* 禁止换行 */
              overflow: hidden;
              /* 隐藏溢出内容 */
              text-overflow: ellipsis;
              /* 显示省略号 */
              width: 360px;
              /* 或者您可以设置一个固定的宽度 */
            }

            .text {
              padding: 20px 20px 50px 20px;
              background: white;
              border: 1px solid #f3f3f3;
              line-height: 1.5em;
              position: relative;

              .detail {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                /* 显示的最大行数 */
                overflow: hidden;
              }

              .button {
                color: #00285a;
                cursor: pointer;
                position: absolute;
                bottom: 20px;
                right: 20px;
              }
            }
          }

          .other_news {
            width: 710px;

            .other_title {
              margin-bottom: 10px;
              border: 1px solid #e7e7e7;
              width: calc(100% - 40px);
              padding: 0 20px;
              line-height: 40px;

              font-size: 18px;
              /* 或者您可以设置一个固定的宽度 */
              display: flex;
              justify-content: space-between;
              cursor: pointer;

              .other_news_title {
                display: inline-block;
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .other_title:hover {
              background: #8e8e8e;
              color: #ffffff;
            }
          }
        }
      }

      .journal {
        // margin-top: 50px;
        padding: 30px;

        .title {
          font-weight: bold;
          font-size: 22px;
          color: #00285a;
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          .inputBox {
            width: 330px;
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-left: 30px;
            position: relative;
          }
        }

        .classification {
          border-top: 1px solid #00285a;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 40px 0 0;

          .classs_item {
            font-size: 16px;
            text-decoration: underline;
            color: #00285a;
            width: 30%;
            margin-bottom: 15px;
            white-space: nowrap;
            /* 禁止换行 */
            overflow: hidden;
            /* 隐藏溢出内容 */
            text-overflow: ellipsis;
            /* 显示省略号 */
            // width: 100%;
            /* 或者您可以设置一个固定的宽度 */
            cursor: pointer;
          }
        }
      }

      .Personnel {
        margin-bottom: 30px;

        .title {
          padding: 30px 30px 0;
          font-weight: bold;
          font-size: 22px;
          color: #00285a;
          margin-bottom: 30px;
        }

        .person_content {
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }


}

@media screen and (max-width: 759px) {
  .home {
    width: 100%;
    overflow-x: hidden;

    .content {
      padding-top: 85px;

      .center {
        width: 100%;
        margin-top: 30px;

        .about {
          width: calc(100% - 60px);


          p {
            line-height: 1.5em;
          }
        }

        .news {
          margin-top: 0px;
          padding: 30px;

          .news_content {
            width: 100%;

            .last {
              display: none;
            }
          }

          .other_news {
            width: 100% !important;
          }
        }
      }

      .journal {

        .title {
          flex-wrap: wrap;

          span {
            margin-bottom: 10px;
          }

          .inputBox {
            margin-left: 0px !important;
          }
        }
      }

      .Personnel {
        width: auto;
        padding: 30px;

        .title {
          font-weight: bold;
          font-size: 22px;
          color: #00285a;
          margin-bottom: 30px;
        }
      }
    }
  }


}
</style>