import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/class',
    name: 'class',
    component: () => import( '../views/ClassList.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import( '../views/NewsDetail.vue')
  },
  {
    //404页面放在最后在前面所以页面无法进入时才会执行
    path: '*',
    name: '404',
    component: () => import("../views/404Page")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
