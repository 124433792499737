<template>
  <div class="header_box">
    <div class="header">
      <div class="center">
        <div class="logo">
          <img src="@/assets/logo.png" alt="">
        </div>
        <div class="nav_button" @click="showPopup">
          <i class="el-icon-s-fold"></i>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="center nav_center">
        <div class="nav_list">
          <div @click="toHome()">Home</div>
          <div><a href="/#news">News</a></div>
          <div><a href="/#journal">Journal Category</a></div>
          <div><a href="/#Personnel">Personnel Mien</a></div>
        </div>
        <div class="button">
          <a href="https://www.springcity.top/user/login?redirect=%2Fdashboard%2Fanalysis">
            PIP
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    toHome() {
      // let href = window.location.href
      if (this.$route.path !== '/') {
        // if(value==1){
        //   this.$router.push({ path: '/', hash: 'news' });
        // }else if(value==2){
        //   this.$router.push({ path: '/', hash: 'journal' });
        // }else if(value==3){
        //   this.$router.push({ path: '/', hash: 'Personnel' });
        // }else{
        this.$router.push('/');
        // }

      }
    },
    showPopup() {
      this.$emit('showPopup')
    },
  }
}
</script>

<style lang="less" scoped>
.header_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;


  .header {
    height: 80px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid #e8e8e8;

    .center {
      width: 1200px;
      margin: auto;

      .nav_button {
        display: none;
      }

      .logo {
        height: 60px;
        max-width: 400px;
        overflow: hidden;
        margin-right: 50px;

        img {
          height: 100%;
          width: auto;
        }
      }
    }


  }

  .nav {
    border-bottom: 4px solid #00285a;

    .nav_center {
      width: 1200px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;

      .nav_list {
        display: flex;
        align-items: center;

        div {
          margin-right: 30px;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
        }

        div:hover {
          text-decoration: underline;
        }
      }

      .button {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 759px) {
  .header_box {
    width: calc(100% - 20px);
    padding: 0 10px;

    .header {

      .center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: end;

        .nav_button {
          display: block;
          width: 25px;
          height: 25px;
          font-size: 25px;
        }
      }


    }

    .nav {
      border-bottom: 4px solid #00285a;

      .nav_center {
        width: 100%;
        display: none;


      }


    }
  }
}</style>