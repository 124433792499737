<template>
    <div class="listPopup" @click="close" :class="{ hiden: webList == false }">
        <div class="content">
            <div class="nav-item" @click="toHome()">Home</div>
            <div class="nav-item"><a href="/#news">News</a></div>
            <div class="nav-item"><a href="/#journal">Journal Category</a></div>
            <div class="nav-item"><a href="/#Personnel">Personnel Mien</a></div>
            <div class="button">
                <a href="https://www.springcity.top/user/login?redirect=%2Fdashboard%2Fanalysis">
                    PIP
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['webList'],
    data() {
        return {

        }
    },
    methods: {
        toHome() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        },
        close() {
            this.$emit('closePopup')
        },

    }

}
</script>

<style lang="less" scoped>
.listPopup {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 1s;

    .content {
        width: 50%;
        height: calc(100vh - 40px);
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        padding: 20px 10px;
        transition: 1s;

        .nav-item {
            padding: 6px 10px;
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 10px;
        }

        .button {
            font-weight: bold;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
}

@media screen and (min-width: 769px) {
    .listPopup {
        right: -100%;
    }
}

.hiden {
    right: -100%;
    transition: 1s;

    .content {
        right: -50%;
        transition: 1s;
    }

}
</style>