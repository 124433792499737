<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in carouselItems" :key="index">
                <div class="box">
                    <div class="img_box">
                        <img :src="'https://www.springcity.top:9999/sys/common/static/' + item.image" alt="">
                    </div>
                    <p style="text-align: center;">{{ item.name }}</p>
                    <p style="text-align: center;">{{ item.job }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.css'  //引入swiper样式
import { get } from '../utils/http'
export default {
    data() {
        return {
            carouselItems: [], // 存储从接口获取的轮播项数据
            screenWidth: null,
            small: false
        };
    },
    watch: {
        screenWidth: function (n) {
            if (n <= 769) {
                this.small = true
            } else {
                this.small = false
            }
            this.fetchCarouselData()
        }

    },


    mounted() {
        // 在组件挂载后，获取轮播项数据（假设这里使用axios进行异步请求）

        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }

    },

    methods: {
        async fetchCarouselData() {
            try {
                // 在这里使用axios或其他方法从接口获取数据
                get('jeecg-portal/portal/employee/list').then((res) => {
                    if (res.success) {
                        this.carouselItems = res.result; // 将数据存储到carouselItems数组中
                        // this.initSwiper();
                        this.$nextTick(() => {
                            if (this.small) {
                                this.initSwiperSamll(); // 等待视图更新后初始化Swiper
                            } else {
                                this.initSwiper(); // 等待视图更新后初始化Swiper
                            }

                        });
                    }
                })

            } catch (error) {
                console.error('获取数据时出错：', error);
            }
        },
        initSwiper() {
            // 初始化Swiper，并在获取数据后执行
            new Swiper('.swiper-container', {
                slidesPerView: 4,
                spaceBetween: 20,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
        initSwiperSamll() {
            // 初始化Swiper，并在获取数据后执行
            new Swiper('.swiper-container', {
                // slidesPerView: 4,
                // spaceBetween: 20,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
    },
};
</script>
<style lang="less">
.box {
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #e0e0e0;

    .img_box {
        width: 100%;
        height: 170px;
        overflow: hidden;
        margin-bottom: 20px;

        img {
            width: 100%;
        }
    }

    p {
        margin-bottom: 15px;
    }
}
</style> 
<style scoped>
/* 根据你的需求添加样式 */

/* .swiper-container {
    width: 100%;
}

.swiper-slide {
    width: 100%;
    text-align: center;
} */
</style>